export default {
    methods: {
        async saveForms() {
            for (const key in this.$refs) {
                console.log(key)
                if (key.startsWith('form')) {
                    this.$refs[key][0].disableAlerts = true
                    await this.$refs[key][0].save()
                }
            }
        }
    }
}
