import GlobalMixin from "components/forms/globalMixin"
export default {
  name: 'FormsMixin',
  mixins: [GlobalMixin],
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  mounted() {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 10)
    //!document.body.classList.contains('forms-module-body') && document.body.classList.add('forms-module-body')
    this.erplayout.erpheader.menu.setActiveMenu('configuracoes')
  },
  destroyed() {
    // document.body.classList.remove('forms-module-body')
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  beforeDestroy() {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  }
}
