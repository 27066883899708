import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'sl.forms'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'name', active: true, sortable: true, ordering: 2},
    {label: 'Categoria', name: 'categoria', active: true, sortable: true, ordering: 3},
    {label: 'Tópicos', name: 'topicos', active: true, sortable: true, ordering: 4},
    {label: 'Campos', name: 'campos', active: true, sortable: true, ordering: 5},
    {label: 'Respostas', name: 'respostas', active: true, sortable: true, ordering: 6},
    {label: 'Ativo', name: 'active', active: true, sortable: true, ordering: 6},
], listVersion)
